<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <template v-for="(detail, idx) in data_form.details">
                    <div class="form-group row" :key="detail.id">
                        <label class="col-lg-3 col-form-label">{{detail.attr_name}} <span class="text-danger">*</span>:</label>
                        <div class="col-lg-6">
                            <input type="text" class="form-control" :placeholder="detail.attr_key" v-model="data_form.details[idx].attr_value" name="attr_value">
                            <span class="form-text text-muted"></span>
                        </div>
                    </div>
                </template>

            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {getApiConfigDetial, updateApiConfig} from '@/api/apiconfig';

    export default {
        name: "update_api_config",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getApiConfigDetial({'id': _this.data_form.id}).then(function(res) {
                if(res && res.response) {
                    _this.data_form.details = res.response;
                }
            });
        },
        components :{

        },
        data() {
            return {
                data_form:{
                    id: this.dialog_data.form_data.id,
                    details: []
                },
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {

                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                updateApiConfig(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            }
        }
    }
</script>
